<template>
  <Layout :tituloPagina="'Notificaciones (' +Intl.NumberFormat('es-MX').format( mensajes_no_leidos )+ ')'">
    <div class="row">
      <div class="col-12">
        <!-- Right Sidebar -->
        <div class="mb-3">
          <div class="row mb-4">
            <div class="col-xl-6 col-md-12">
              <div class="pb-3 pb-xl-0">
                <filtrador
                  v-on:dato-actualizado="
                    dato = $event,
                    refrescarMensajes()
                  "
                  :datoini="dato"
                  placeholder="Dato del Mensaje"
                ></filtrador>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-8 col-md-12">
              <div class="pb-3 pb-xl-0">
                <div class="btn-toolbar">
                  <div class="input-group me-2 mb-2">
                    <select
                      class="form-select btn-outline-secondary"
                      v-model="seleccionar"
                      title="Seleccionar"
                      @change="seleccionarMensajes()"
                    >
                      <option :value="1">Nada</option>
                      <option :value="2">Todos</option>
                      <option :value="3">Leidos</option>
                      <option :value="4">No leidos</option>
                    </select>
                    <router-link
                      :to="{name: 'redactarNotificacion'}"
                      class="btn btn-white btn-outline-success"
                      title="Redactar mensaje nuevo"
                    >
                      <span class="d-none d-sm-block">
                        <i class="mdi mdi-lead-pencil"></i>
                        Redactar mensaje
                      </span>
                      <span class="d-block d-sm-none">
                        <i class="mdi mdi-lead-pencil"></i>
                      </span>
                    </router-link>
                    <button
                      class="btn btn-white btn-outline-success"
                      title="Actualizar inbox"
                      @click="refrescarMensajes()"
                    >
                      <i class="mdi mdi-refresh"></i>
                    </button>
                  </div>
                  <div class="btn-group me-2 mb-2">
                    <button
                      class="btn btn-white btn-outline-success"
                      title="Marcar mensajes como leidos"
                      v-show="mostrarMesajesMarcados > 0"
                      @click="marcarMensajesSeleccionadosComoVisto()"
                    >
                      <i class="mdi mdi-email-open-outline"></i>
                    </button>
                    <button
                      class="btn btn-white btn-outline-primary"
                      title="Marcar mensajes como no leidos"
                      v-show="mostrarMesajesMarcados > 0"
                      @click="marcarMensajesSeleccionadosComoNoVisto()"
                    >
                      <i class="mdi mdi-email-outline"></i>
                    </button>
                    <button
                      class="btn btn-white btn-outline-danger"
                      title="Eliminar mensajes seleccionados"
                      v-show="mostrarMesajesMarcados > 0"
                      @click="eliminarMensajesSeleccionados()"
                    >
                      <i class="mdi mdi-trash-can-outline"></i>
                    </button>
                  </div>
                  <div class="btn-group me-2 mb-2">
                    <button
                      class="btn btn-white btn-outline-danger"
                      title="Eliminar todos los mensajes"
                      v-show="seleccionar == 2 && mostrarMesajesMarcados >= mensajes.length && mensajes.length > 0 && tn > per_page"
                      @click="eliminarTodosMensajes()"
                    >
                      <span class="d-none d-sm-block">
                        <i class="mdi mdi-trash-can-outline"></i>
                        Eliminar {{ tn > 1 ? 'los '+Intl.NumberFormat('es-MX').format( tn )+ ' mensajes' : 'un mensaje'}} 
                      </span>
                      <span class="d-block d-sm-none">
                        <i class="mdi mdi-trash-can-outline"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-md-12">
              <div class="pb-0 float-end">
                <paginador
                  :pag="page"
                  :totpag="totpag"
                  :epp="per_page"
                  @cargar-pagina="cargarPagina"
                  @epp-actualizado="
                    per_page = $event,
                    refrescarMensajes()
                  "
                ></paginador>
              </div>
            </div>
          </div>

          <div id="bts-notificaciones" style="min-height: 475px;">
            <div class="card mb-2" v-for="mensaje in mensajes" :key="mensaje.id" >
              <div class="message-list mb-0 p-1">
                <div class="list" :class="!mensaje.visto ? 'active unread' : ''">
                  <div class="col-mail col-mail-1">
                    <div class="checkbox-wrapper-mail">
                      <input 
                        type="checkbox"
                        v-model="mensaje.seleccionar"
                        @change="mensajeSelec = !mensajeSelec"
                        :title="!mensaje.seleccionar?'Seleccionar mensaje':'Deseleccionar mensaje'"
                        :id="'check-'+mensaje.id">
                      <label :for="'check-'+mensaje.id" class="toggle"></label>
                    </div>

                    <div class="d-flex title align-items-center cursor" style="left: 52px;" @click="visorNotificacion(mensaje.id)">
                      <template v-if="mensaje.id_usuario_emisor != null">
                        <img
                          :src="
                            `${API}/usuarios/${
                              mensaje.id_usuario_emisor
                            }/foto?_tk=${token}&tipo_foto=64x64`
                          "
                          class="avatar-sm rounded-circle d-none d-sm-block"
                          v-if="mensaje.existe_foto_usuario_emisor"
                        />
                        <img 
                          alt="image" class="avatar-sm rounded-circle d-none d-sm-block"
                          :src="usuarioicon" style="width: 30px"
                          v-if="!mensaje.existe_foto_usuario_emisor"
                        />
                      </template>
                      <template v-if="mensaje.id_usuario_emisor == null">
                        <div class="d-none d-sm-block auth-logo">
                          <img 
                            alt="image" class="avatar-sm rounded-circle auth-logo-dark"
                            :src="argusicon" style="width: 30px"
                          />
                          <img 
                            alt="image" class="avatar-sm rounded-circle auth-logo-light"
                            :src="argusiconblanco" style="width: 30px"
                          />
                        </div>
                      </template>

                      <div class="flex-1 ms-2 ps-1">
                        <h5 class="font-size-13 mb-0">
                          <span class="text-dark">
                            {{mensaje.id_usuario_emisor != null ? mensaje.nombre_usuario_emisor : 'Sistema ArgusBlack'}}
                          </span>
                        </h5>
                        <span class="text-muted text-uppercase font-size-12">
                          {{mensaje.asunto}}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-mail col-mail-2">
                    <span class="subject text-dark cursor d-none d-sm-block" @click="visorNotificacion(mensaje.id)">
                      {{mensaje.asunto}} - 
                      <span class="teaser text-muted fw-normal">
                        Fecha: {{formatoFecha(mensaje.created_at, 'dddd, DD/MM/YYYY')}}
                      </span>
                    </span>
                    <div class="date" style="width: 250px;">
                      <span class="bx-pull-left cursor d-none d-sm-block" @click="visorNotificacion(mensaje.id)">
                        {{formatoFecha(mensaje.created_at, 'hh:mm a')}}
                      </span>
                      <div class="btn-group btn-group-sm bx-pull-right">
                        <button
                          class="btn btn-light bg-gradient btn-outline-info waves-effect waves-light"
                          title="Mostrar mensaje"
                          @click="visorNotificacion(mensaje.id)"
                        >
                          <i class="mdi mdi-eye-outline"></i>
                        </button>
                        <button
                          class="btn btn-light bg-gradient btn-outline-success waves-effect waves-light"
                          @click="!mensaje.visto ? marcarMensajeLeido(mensaje) : marcarMensajeNoLeido(mensaje)"
                          :title="!mensaje.visto ? 'Marcar mensaje como leído' : 'Marcar mensaje como no leído'"
                        >
                          <i class="mdi" :class="mensaje.visto ? 'mdi-email-outline': 'mdi-email-open-outline'"></i>
                        </button>
                        <button
                          class="btn btn-light bg-gradient btn-outline-danger waves-effect waves-light"
                          @click="eliminarMensaje(mensaje)"
                          title="Eliminar mensaje"
                        >
                          <i class="mdi mdi-trash-can-outline"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end Col-9 -->
      </div>
    </div><!-- End row -->
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'

import MensajeUsuarioSrv from '@/services/MensajeUsuarioSrv.js'
import usuarioicon from '@/assets/img/usuario.png'
import argusicon from '@/assets/img/logoArgus.png'
import argusiconblanco from '@/assets/img/logoArgusBlanco.png'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import API from '@/API.js'

export default {
  name: 'ListadoNotificaciones',
  components: {
    Layout,
    Filtrador,
    Paginador
  },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      usuarioicon: usuarioicon,
      argusicon: argusicon,
      argusiconblanco: argusiconblanco,
      dato: '',
      page: 1,
      per_page: parseInt(localStorage.getItem('argusblack.listadoNotificaciones.por_pagina') || 10),
      tn: 0, // Total de notificaciones
      totpag: 0, // Total de páginas
      mensajes: [],
      mensajes_no_leidos: 0,
      mensajeSelec: false,
      seleccionar: 1, // 1: Nada, 2: Todos, 3: Leidos, 4: No leidos
      usuario: {}
    }
  },

  created: function() {
    var self = this

    if (self.$route.query.dato) self.dato = self.$route.query.dato
    if (self.$route.query.page) self.page = parseInt(self.$route.query.page)
    if (self.$route.query.per_page) self.per_page = parseInt(self.$route.query.per_page)
    if (self.$route.query.seleccionar) self.seleccionar = parseInt(self.$route.query.seleccionar)

    self.cargarUsuarioActual()
  },

  computed: {
    mostrarMesajesMarcados () {
      var self = this, mensajes = []

      if(self.seleccionar != null) mensajes = self.mensajes.filter(mensaje => mensaje.seleccionar == true)

      if(self.mensajeSelec) mensajes = self.mensajes.filter(mensaje => mensaje.seleccionar == true)
      else mensajes = self.mensajes.filter(mensaje => mensaje.seleccionar == true)

      return mensajes.length
    }
  },

  methods: {
    cantidadNoLeidos: function() {
      var self = this

      MensajeUsuarioSrv.cantidadNoLeidos({id_usuario_receptor: self.usuario.id}).then(response => {
        self.mensajes_no_leidos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se a podido optener la cantidad de notificaciones"
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(()=> {
        iu.bus.emit('actualizarNNotificaciones', self.mensajes_no_leidos)
        iu.bus.emit('actualizarCampana')
      })
    },

    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarMensajes({ page: n })
    },

    cargarUsuarioActual: function() {
      var self = this

      UsuarioSrv.actual().then(response => {
        // Si se obtuvo el usuario, se manda a guardar en el storage
        self.usuario = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se a podido optener el usuario actual de notificaciones"
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.refrescarMensajes()
      })
    },

    cortarMensaje(mensaje){
      let mensaje_cortado = mensaje.substr(0, 50);
      if(mensaje.length > 50) mensaje_cortado += '...'
      return mensaje_cortado
    },

    eliminarMensaje(mensaje){
      var self = this 

      var nombre = mensaje.id_usuario_emisor != null ? mensaje.nombre_usuario_emisor : 'Sistema ArgusBlack'
      
      MensajeUsuarioSrv.eliminar(mensaje.id).then(response => {
        iu.msg.success("Mensaje de "+nombre+" eliminado.")
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo eliminar el mensaje de "+nombre
        }
        iu.msg.error(mensaje);
        console.log(error)
      }).finally(() => {
        self.refrescarMensajes()
      })
    },

    eliminarTodosMensajes(){
      var self = this, 
        params = {
          todos: true,
          id_usuario_receptor: self.usuario.id
        }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminar todos los mensajes",
        html: `¿Estás seguro de eliminar todos los mensajes?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true,
      }).then(result => {
        if (result.value) {
          MensajeUsuarioSrv.eliminarMensajes(params).then(response => {
            swal.fire("Eliminado!", "Se eliminaron todos los mensajes correctamente", "success");
            self.refrescarMensajes()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudieron eliminar los mensajes'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },


    eliminarMensajesSeleccionados: function() {
      var self = this, idsMensajes=[]

      var mensajesSelecionadas = self.mensajes.filter(mensaje => mensaje.seleccionar == true)

      if(mensajesSelecionadas.length == 0) {
        iu.msg.warning('No se han seleccionado mensajes para eliminar')
        return
      }

      mensajesSelecionadas.forEach(mensajeSec => {
        idsMensajes.push(mensajeSec.id)
      })

      var params = {
        ids: idsMensajes
      }
      
      MensajeUsuarioSrv.eliminarMensajes(params).then(response => {
        iu.msg.success("¡Los mensajes seleccionados se han eliminado correctamente!.");
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se han podido eliminar los mensajes seleccionados"
        }
        iu.msg.error(mensaje);
        console.log(error)
      }).finally(() => {
        self.refrescarMensajes()
      })
    },

    formatoFecha(mt, formato = 'DD/MM/YYYY') {
      return moment(mt).locale('es-mx').format(formato)
    },

    marcarMensajeLeido(mensaje){
      var self = this 

      var nombre = mensaje.id_usuario_emisor != null ? mensaje.nombre_usuario_emisor : 'Sistema ArgusBlack'
      
      MensajeUsuarioSrv.marcarMensajeVisto(mensaje.id).then(response => {
        iu.msg.success("Mensaje de "+nombre+" se ha marcado como leído")
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo marcar en leído el mensaje de "+nombre
        }
        iu.msg.error(mensaje);
        console.log(error)
      }).finally(() => {
        self.refrescarMensajes()
      })
      
    },

    marcarMensajeNoLeido(mensaje){
      var self = this 

      var nombre = mensaje.id_usuario_emisor != null ? mensaje.nombre_usuario_emisor : 'Sistema ArgusBlack'

      MensajeUsuarioSrv.marcarMensajeNoVisto(mensaje.id).then(response => {
        iu.msg.success("Mensaje de "+nombre+" se ha marcado como no leído");
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se pudo marcar en no leído el mensaje de "+nombre
        }
        iu.msg.error(mensaje);
        console.log(error)
      }).finally(() => {
        self.refrescarMensajes()
      })
      
    },

    marcarMensajesSeleccionadosComoVisto: function() {
      var self = this, idsMensajes=[]

      var mensajesSelecionadas = self.mensajes.filter(mensaje => mensaje.seleccionar == true)

      if(mensajesSelecionadas.length == 0) {
        iu.msg.warning('No se han seleccionado mensajes para marcar como leídos')
        return
      }

      mensajesSelecionadas.forEach(mensajeSec => {
        idsMensajes.push(mensajeSec.id)
      })

      var params = {
        ids: idsMensajes
      }

      MensajeUsuarioSrv.marcarMensajesVistos(params).then(response => {
        iu.msg.success("¡Los mensajes seleccionados se han marcado como leídos!.");
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se han podido marcar como leídos los mensajes seleccionados"
        }
        iu.msg.error(mensaje);
        console.log(error)
      }).finally(() => {
        self.refrescarMensajes()
      })
    },

    marcarMensajesSeleccionadosComoNoVisto: function() {
      var self = this, idsMensajes=[]

      var mensajesSelecionadas = self.mensajes.filter(mensaje => mensaje.seleccionar == true)

      if(mensajesSelecionadas.length == 0) {
        iu.msg.warning('No se han seleccionado mensajes para marcar como no leídos')
        return
      }

      mensajesSelecionadas.forEach(mensajeSec => {
        idsMensajes.push(mensajeSec.id)
      })

      var params = {
        ids: idsMensajes
      }

      MensajeUsuarioSrv.marcarMensajesNoVistos(params).then(response => {
        iu.msg.success("¡Los mensajes seleccionados se han marcado como no leídos!.");
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se han podido marcar como no leídos los mensajes seleccionados"
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.refrescarMensajes()
      })
    },

    seleccionarMensajes: function(){
      var self = this

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          per_page: self.per_page,
          seleccionar: self.seleccionar
        }
      }).catch(error => {})

      self.mensajes.forEach(mensaje => {
        mensaje.seleccionar = false
      })

      switch(this.seleccionar) {
        case 1: // Nada
          self.mensajes.forEach(mensaje => {
            mensaje.seleccionar = false
          })
        break;
        case 2: // Todos
          self.mensajes.forEach(mensaje => {
            mensaje.seleccionar = true
          })
        break;
        case 3: // Leidos
          self.mensajes.forEach(mensaje => {
            if(mensaje.visto) mensaje.seleccionar = true
          })
        break;
        case 4: // No leidos
          self.mensajes.forEach(mensaje => {
            if(!mensaje.visto) mensaje.seleccionar = true
          })
        break;
      }
    },
    
    refrescarMensajes: function() {
      var self = this

      iu.spinner.mostrar('#bts-notificaciones')

      this.$router.replace({
        query: {
          dato: self.dato,
          page: self.page,
          per_page: self.per_page,
          seleccionar: self.seleccionar
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        per_page: self.per_page,
        dato: self.dato,
        id_usuario_receptor: self.usuario.id
      }

      MensajeUsuarioSrv.mensajesJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoNotificaciones.por_pagina', self.per_page)
        let page = response.data
        self.mensajes = page.data
        self.tn = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarMensajes()
        }

        self.seleccionarMensajes()
        self.cantidadNoLeidos()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los mensajes de usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#bts-notificaciones')
      })
    },

    visorNotificacion: function (idMensaje) {
      var self = this

      MensajeUsuarioSrv.marcarMensajeVisto(idMensaje).then(response => {
        this.$router.push({
          name: 'visorNotificacion',
          params: {
            id: idMensaje
          }
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = "No se a podido marcar el mensaje como leido"
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#bts-notificaciones')
  }
}
</script>

<style scoped>
.cursor{
  cursor: pointer;
}
</style>